import React, { useState } from 'react'


import Programs from '../components/Programs/Programs'

const Courses = () => {


    return (
        <>
            <div style={{marginTop:"100px",minHeight:"500px",textAlign:"center"}}>
<h1>Our Courses</h1>
              <Programs/>  

            </div>
        </>
    )
}

export default Courses
